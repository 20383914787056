import React from "react";
import loadable from "@loadable/component";
import { mobileFAQList } from "../components/ApplicationsDevelopment/MobileDevelopment/MobileDevelopment";

const Layout = loadable(() => import("@common/Layout/Layout"));
const MobileDevelopment = loadable(() =>
    import(
        "@components/ApplicationsDevelopment/MobileDevelopment/MobileDevelopment"
    )
);
const SEO = loadable(() => import("@components/Common/SEO/SEO"));
const MobileDevelopmentPage = ({ path }) => (
    <>
        <SEO
            title="Custom iOS and Android Mobile App Development Services"
            titleTemplate="Impressit"
            description="Hire mobile app developers with Impressit! Custom mobile app development for iOS and Android with the team of the best tech professionals."
            path={path}
            faqSchema={mobileFAQList}
        />
        <Layout path={path}>
            <MobileDevelopment />
        </Layout>
    </>
);

export default MobileDevelopmentPage;
