import React from "react";
import loadable from "@loadable/component";
import { GLOBAL_APPLICATION_INTERNAL_LINKS } from "@links";
import {
    CATCHUP_SECTION_BLUR_WIDTH,
    CATCHUP_SECTION_BLUR_HEIGHT,
} from "@constants";
import { GlobalStyle } from "../Styles/GlobalStyle";
import FAQSection from "../../Common/FAQSection/FAQSection";
import backgroundImage from "@images/mobile-development/backgroundImage.jpg";
import { 
    SECOND_CATCH_UP_SECTION_TITLE, 
    SECOND_CATCH_UP_SECTION_DESCRIPTION,
    FEEDBACK_SECTION_ITEMS
} from "../constants";

const CircleBLur = loadable(() => import("@common/CircleBlur/CircleBlur"));

const ProcessSection = loadable(() =>
    import("./components/ProcessSection/ProcessSection")
);

const FeedbackSection = loadable(() => import("@common/SlideShow/ContentWithFeedback/FeedbacksSection/FeedbacksSection"));

const WhyChooseSection = loadable(() => import("./components/WhyChooseSection"));

const OffersSection = loadable(() => import("./components/OffersSection"));

const TechnologySection = loadable(() =>
    import("./components/TechnologySection/TechnologySection")
);

const TextTitleSection = loadable(() =>
    import("@common/CommonSections/TextTitleSection/TextTitleSection")
);

const CrawlableSecondCatchUpSection = loadable(() =>
    import("@common/CommonSections/CrawlableSecondCatchUpSection")
);

const LinkButton = loadable(() => import("@common/LinkButton"));

export const mobileFAQList = [
    {
        title: "What are the different types of mobile apps?",
        iconPath: [null],
        description: (
            <>
                There are three main types of mobile apps: native apps, web
                apps, and cross-platform apps.
                <br />
                Native apps are built specifically for a particular mobile
                operating system, such as iOS or Android. They are written in a
                programming language that is native to that platform and can
                access the device's hardware and software features.
                <br />
                Web apps are mobile-friendly websites that are optimized for use
                on mobile devices. They run within the device's web browser and
                are built using standard web technologies such as HTML, CSS, and
                JavaScript.
                <br />
                Cross-platform apps are a combination of native and web apps. They are
                built using web technologies like HTML, CSS, and JavaScript, but
                are wrapped in a native app shell that allows them to be
                downloaded and installed on a device like a native app.
            </>
        ),
    },
    {
        title: "What is the difference between cross-platform app development and cross-platform mobile app development?",
        iconPath: [null],
        description: (
            <>
                Cross-platform custom mobile app development involves using a
                single codebase to build an app that can run on multiple
                platforms, such as iOS and Android. Native cross-platform
                development involves using frameworks that compile the code to
                the native code of each platform. Non-native cross-platform
                development involves using web technologies like HTML, CSS, and
                JavaScript to build an app that runs within a webview wrapper on
                each platform.
                <br />
                On the other hand, cross-platform mobile app development involves
                building an app that combines both native and web app elements.
                A cross-platform app is essentially a web app that is wrapped in a
                native app shell, which allows it to be installed and run like a
                native app.
                <br />
                In summary, the main difference between cross-platform app
                development and cross-platform mobile app development is that
                cross-platform app development allows you to write code once and
                deploy it to multiple platforms, while cross-platform app development
                involves building an app that combines both native and web app
                elements.
            </>
        ),
    },
    {
        title: "What programming languages and technologies are used for mobile app development?",
        iconPath: [null],
        description: (
            <>
                There are several programming languages and technologies that
                are commonly used for mobile app development, depending on the
                type of app and the platform for which it is being developed:
                <br />
                - Java and Kotlin are the primary programming languages used for
                developing Android apps.
                <br />
                - Swift and Objective-C are the primary programming languages
                used for developing iOS apps.
                <br />
                - JavaScript, HTML, and CSS are the primary technologies used
                for building web apps and cross-platform apps.
                <br />
                - C# and .NET are the primary programming languages and
                frameworks used for developing cross-platform apps using
                Xamarin, a popular cross-platform development tool.
                <br />
                - React Native is a popular JavaScript framework for developing
                cross-platform mobile apps that look and feel like native apps.
                <br />
                - Flutter is a relatively new mobile development framework from
                Google that uses the Dart programming language. Flutter allows
                developers to build high-performance, visually attractive apps
                for both iOS and Android from a single codebase.
                <br />
                Other popular technologies and frameworks for mobile app
                development include PhoneGap, Ionic, and Cordova for cross-platform app
                development, and Unity for developing mobile games.
            </>
        ),
    },
    {
        title: "What are the benefits of outsourcing mobile app development?",
        iconPath: [null],
        description: (
            <>
                Some of the main benefits of outsourcing mobile application
                development services:
                <br />
                - Cost savings. Outsourcing can help reduce overhead costs such
                as salaries, benefits, and office space, as well as the cost of
                acquiring software licenses and equipment.
                <br />
                - Access to specialized expertise. Outsourcing firms often have
                a team of developers with a wide range of skills and experience,
                which can help ensure that the app is built to a high standard
                and meets the specific needs of the client.
                <br />
                - Faster time-to-market. Outsourcing firms often have a
                dedicated team of developers who can work on the project
                full-time, which can help accelerate the development process and
                reduce the time it takes to launch the app.
                <br />
                - Flexibility. Outsourcing firms can work with clients to
                determine the scope of the project and the budget, and can
                adjust the project as needed to meet the client's changing
                needs.
                <br />- Focus on core business activities. Outsourcing mobile
                app development can allow businesses to focus on their core
                activities, such as sales and marketing, while leaving the app
                development to the experts.
            </>
        ),
    },
    {
        title: "How much does it cost to develop a mobile app?",
        iconPath: [null],
        description: (
            <>
                The cost of developing a mobile app can vary greatly depending
                on various factors, such as the complexity of the app, the
                platform(s) for which it is being developed, the location and
                experience of the development team, and the features and
                functionality required. However, here are some rough estimates
                of the average custom mobile app development services price:
                <br />
                - Simple mobile apps with basic functionality and no backend
                system can cost anywhere from $5,000 to $20,000.
                <br />
                - Mid-level apps, such as integration with third-party services,
                backend integration, and better user interfaces, can cost
                anywhere from $20,000 to $50,000.
                <br />
                - Large and complex apps with advanced functionality, such as
                social media apps, e-commerce apps, and on-demand apps, can cost
                upwards of $50,000 to $250,000 or more.
                <br />
                - Gaming apps are typically the most expensive to develop due to
                the need for high-quality graphics, animation, and sound
                effects. The cost can range from $10,000 to $250,000 or more.
                <br />
                Additionally, ongoing costs such as maintenance, updates, and
                marketing should also be factored into the overall cost of
                developing a mobile app.
            </>
        ),
    },
    {
        title: "How long does it take to build a mobile app?",
        iconPath: [null],
        description: (
            <>
                Generally, a simple mobile app with basic functionality can take
                around 2-3 months to develop. A more complex app with advanced
                features and integrations can take anywhere from 4-6 months or
                more to complete.
                <br />
                The time for a custom mobile app development can vary depending
                on several factors such as the complexity of the app, the
                features required, the platform on which it will be built, the
                technology stack used, and the team size working on the project.
                However, it's important to note that mobile app development is
                an iterative process, and the timeline can be affected by
                various factors such as changes in the project requirements,
                technical issues that may arise during development, and team
                availability.
            </>
        ),
    },
    {
        title: "What solutions are provided by mobile app development services?",
        iconPath: [null],
        description: (
            <>
                What solutions are provided by mobile app development services?
                <br />
                Impressit as a custom mobile app development company provides a
                range of solutions to businesses and individuals who want to
                create custom mobile applications:
                <br />
                - Mobile App Development: App development services can develop
                custom mobile apps for both iOS and Android platforms. This
                includes coding, testing, and deploying the app to the app
                stores.
                <br />
                - Cross-Platform Development: Some app development services can
                develop apps that work across multiple platforms using
                frameworks such as React Native, Xamarin, or Flutter.
                <br />
                - Mobile App Design: App development services can help design a
                mobile app's user interface (UI) and user experience (UX),
                ensuring it is visually appealing, easy to navigate, and
                intuitive for users.
                <br />
                - App Maintenance and Support: After an app is developed and
                launched, app development services can provide ongoing
                maintenance and support, ensuring the app is up-to-date, secure,
                and bug-free.
                <br />
                Overall, mobile app development services provide end-to-end
                solutions for businesses and individuals looking to create
                custom mobile apps.
            </>
        ),
    },
    {
        title: "Why choose Impressit for mobile app development?",
        iconPath: [null],
        description: (
            <>
                Overall, selecting the right mobile app development company can
                be a crucial decision that can impact the success of your
                project. Impressit is the right choice if you are looking for a
                custom mobile app development company. Impressit offers:
                <br />
                - Experience and Technical Expertise. Our team consists of
                talented and skilled professionals who are not afraid of
                challenges. Our Case Study page includes examples of our past
                successful projects.
                <br />
                - Great Communication and Collaboration. We know and firmly
                believe that trust and transparent communication are absolutely
                essential for any business collaboration.
                <br />
                - Perfect Price-Quality Ratio. Choosing a company solely based
                on the lowest price is wrong, as quality and expertise are also
                important factors. Impressit guarantees the best services and
                brilliant results.
                <br />- Excellent Reputation and Reviews: Our levels of
                professionalism, quality of work, and customer satisfaction are
                proven with our case studies and our clients’ reviews.
            </>
        ),
    },
];

const MobileDevelopment = () => (
    <div>
        <GlobalStyle />
        <TextTitleSection
            title="Mobile app development"
            description={`
                    With numerous mobile applications appearing on the market daily,
                    it may be challenging to succeed. Finding the right custom mobile
                    app development company is the thing that can make it or break it.
                    Choosing Impressit is your key to success. If you need
                    to hire mobile app developers (whether its iOS or Android) or
                    multi-platform support for your product — we are here to help.
                    We will combine your vision and our expertise into a fast, scalable,
                    and user-friendly mobile application.
                `}
            backgroundImage={backgroundImage}
        />
        <OffersSection />
        <ProcessSection />
        <TechnologySection />
        <FeedbackSection
            feedbacks={FEEDBACK_SECTION_ITEMS}
            title="Our Clients say"
        />
        <WhyChooseSection />
        <FAQSection title="FAQ" FAQList={mobileFAQList} />
        <div className="catch-up-section__container">
            <CircleBLur
                width={CATCHUP_SECTION_BLUR_WIDTH}
                height={CATCHUP_SECTION_BLUR_HEIGHT}
            />
            <CrawlableSecondCatchUpSection
                title={SECOND_CATCH_UP_SECTION_TITLE}
                description={SECOND_CATCH_UP_SECTION_DESCRIPTION}
                >
                <LinkButton to={GLOBAL_APPLICATION_INTERNAL_LINKS.CONTACT_US}>
                    Contact us
                </LinkButton>
            </CrawlableSecondCatchUpSection>
        </div>
    </div>
);

export default MobileDevelopment;
